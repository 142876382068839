.sign-up-mode {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  color: #f5f8f8;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}
.sign-up-mode::before {
  content: "";
  position: absolute;
  width: 2000px;
  height: 2000px;
  border-radius: 50%;
  background: linear-gradient(-45deg, #4481eb, #04befe);
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  z-index: 6;
  transition: 1.8s ease-in-out;
}
.slider {
  width: 95%;
  box-shadow: 0 7px 4px rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
}
.logo {
  float: right;
  top: 0;
  margin-right: 30px;
  width: 150px;
  height: 80px;
}
.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 5rem;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}
form .sign-in-form {
  z-index: 2;
}

.signinForm {
  position: absolute;
  top: 50%;
  left: 75%;
  transform: translate(-50%, -50%);
  width: 50%;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}
.title {
  font-size: 2.2rem;
  color: #444;
  margin-bottom: 10px;
}

.btn {
  width: 150px;
  height: 49px;
  border: none;
  outline: none;
  border-radius: 49px;
  cursor: pointer;
  background-color: #5995fd;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  transition: 0.5s;
}
.btn:hover {
  background-color: #4d84e2;
}
.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}
.panels-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 7;
  top: -5%;
}
.left-panel {
  padding: 3rem 17% 2rem 12%;
  pointer-events: all;
}

.panel .content {
  color: #fff;
  transition: 0.9s 0.6s ease-in-out;
}
.panel h3 {
  font-weight: 600;
  font-family: fantasy;
  line-height: 1;
  font-size: 1.5rem;
}
.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}
.btn.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}
.image {
  width: 80%;
  transition: 0.9s 0.6s ease-in-out;
}
@media only screen and (max-width: 400px) and (max-width: 600px) {
  .sign-up-mode {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    color: #f5f8f8;
  }
  .sign-up-mode::before {
    content: "";
    position: absolute;
    width: 2000px;
    height: 2000px;
    border-radius: 50%;
    background: linear-gradient(-45deg, #4481eb, #04befe);
    top: -30%;
    right: 0;
    transform: translateY(-50%);
    z-index: 6;
    transition: 1.8s ease-in-out;
  }
  .signinForm {
    transform: translate(-70%, 100%);
    min-width: 120%;
    max-width: 120%;
    z-index: 5;
  }
  .content {
    transform: translateX(20%);
  }
  .image {
    transform: translate(60%, -30%);
    width: 100%;
    height: 60%;
  }
  .logo {
    float: right;
    top: 0;
    margin-right: -1px;
    width: 30px;
    height: 20px;
  }
}
