* {
    scroll-behavior: smooth;
  }

.progress {
    height: 10px;
    border-radius: 10px
}


.progress div {
    background-color: red
}

.text1 {
    font-size: 14px;
    font-weight: 600
}

.text2 {
    color: #a5aec0
}

.card {
    border: none;
    border-radius: 10px;
}

.c-details span {
    font-weight: 300;
    font-size: 13px
}

.icon {
    width: 50px;
    height: 50px;
    background-color: #eee;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 39px;
    margin-right: 10px;
    
}

.badge_w span {
    background-color: #ffd7d7;
    width: 40px;
    height: 15px;
    padding-bottom: 3px;
    font-size: medium;
    border-radius: 5px;
    display: flex;
    color: red;
    justify-content: center;
    align-items: center
}

.badge_s span {
    background-color: #a6e9ad;
    width: 40px;
    height: 15px;
    padding-bottom: 3px;
    font-size: medium;
    border-radius: 5px;
    display: flex;
    color: green;
    justify-content: center;
    align-items: center
}

.card_project{
    /* position: relative; */
    margin: 2rem 5rem 2rem 2rem;
    border: 3px solid black;
    border-radius: 1rem;
    width: 13vw;
    /* margin-right: 8vw; */
}

@media (max-width: 820px) {
    .card_project {
        width: 60%;
    }
}




.container{
    display: flex;
    justify-content: space-around;
    flex-wrap:wrap;

   
    
} 