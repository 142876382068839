.searchbar {
  float: right;
  border: 1px solid black;
  margin: 10px;
}
.searchbar input {
  border-style: hidden;
  border: 1px solid white;
  margin: 2px;
}
.searchbar input:focus {
  outline: none;
  scale: 1.02;
}
.searchbar .icon {
  padding-left: 5px;
}

.summary {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
